(function () {
    $(function () {
        let $filter = $('[data-filter="work"]');

        if ($filter.length > 0) {
            const axios = require('axios').default;
            window.axios = axios;

            window.source = undefined;

            $filter.each(function () {
                let $element = $(this);

                let reloadGrid = function (href) {
                    // Cancel previous request
                    if (window.source !== undefined) {
                        // Cancel previous request
                        window.source.cancel();
                    }

                    let $grid = $element.closest('.dt-works-grid-container').find('.masonry');
                    window.source = window.axios.CancelToken.source();

                    window.axios.get(href, {cancelToken: source.token})
                        .then(function (response) {
                            let $items = $grid.find('.masonry-column');
                            $grid.masonry('remove', $items);

                            let infScroll = $grid.data('infiniteScroll');

                            if (infScroll !== undefined) {
                                $grid.infiniteScroll('destroy');
                            }

                            let $data = $(response.data);

                            $data.find('.masonry-column').each(function () {
                                let $this = $(this);

                                // Append pagination
                                let $pagination = $('.dt-works-grid .pagination');
                                let $newPagination = $data.find('.dt-works-grid .pagination').html();
                                $newPagination = $newPagination ? $newPagination : '';

                                $pagination.html($newPagination);

                                $grid = $grid.append($this)
                                    .masonry('appended', $this)
                                    .masonry({
                                        itemSelector: '.masonry-column'
                                    });

                                if ($newPagination !== '') {
                                    let msnry = $grid.data('masonry');

                                    $grid.infiniteScroll({
                                        path: '.pagination__next',
                                        nextSelector: '.pagination__next',
                                        append: '[data-content="infinite-load"]',
                                        history: false,
                                        checkLastPage: true,
                                        hideNav: '.pagination__next',
                                        debug: true,
                                        outlayer: msnry,
                                    });
                                }
                            });

                            window.source = undefined;
                        }).catch(() => { /*Silence is good*/
                    });
                }

                // Close click out
                $(document).on('click', function (event) {
                    let exists = $element.find(event.target).length > 0;

                    let _opened = $element.find('.dt-works-grid-filter-list').hasClass("show");

                    if (_opened === true && !exists) {
                        $element.find('[data-toggle="collapse"]').trigger('click');
                    }
                });

                $element.on('filter.applied filter.clean.all', function () {
                    let href = $element.attr('action');

                    let filters = [];

                    $element.find('[data-type="selected-container"] [data-filter]').each(function () {
                        let $this = $(this);
                        let filter = {
                            name: $this.data('filter'),
                            value: $this.data('value'),
                        };

                        filters.push(filter);
                    });

                    let data = {};

                    $(filters).each(function (index, obj) {
                        if (data[obj.name] === undefined) {
                            data[obj.name] = [obj.value];
                        } else {
                            data[obj.name].push(obj.value);
                        }
                    });

                    try {
                        function encodeQueryData(data) {
                            const ret = [];

                            for (let d in data) {
                                let value = data[d];

                                if (Array.isArray(value)) {
                                    for (let i in value) {
                                        ret.push(encodeURIComponent(d) + '[]=' + encodeURIComponent(value[i]));
                                    }
                                } else {
                                    ret.push(encodeURIComponent(d) + '=' + encodeURIComponent(data[d]));
                                }
                            }

                            return ret.join('&');
                        }

                        let filters = encodeQueryData(data);

                        let href_query = href + (filters ? '?' + filters : '');

                        window.history.pushState({href: href_query}, '', href_query);

                        reloadGrid(document.location);
                    } catch (e) {
                    }
                });

                let fn = function (e) {
                    let $filter = $(e.currentTarget);

                    let $form = $filter.closest('[data-filter="work"]');
                    let filter_data = '[data-filter="' + $filter.data('filter') + '"][data-value="' + $filter.data('value') + '"]';

                    // List of applied
                    let $filterList = $form.closest('[data-filter="work"]').find('[data-type="selected"]');
                    let $filterListContainer = $filterList.find('[data-type="selected-container"]');

                    let $filterApplied = $filterListContainer.find(filter_data);

                    if ($filterApplied.length > 0) {
                        $filterApplied.remove();
                    } else {
                        let $filter_clone = $filter.clone();

                        $filter_clone.on('click', fn);
                        $filterListContainer.append($filter_clone);
                    }

                    //
                    if (!$form.find(filter_data).hasClass('active')) {
                        $form.find(filter_data).addClass('active');
                    } else {
                        $form.find(filter_data).removeClass('active');
                    }

                    // Check if container is empty
                    if (!$filterListContainer.html().trim()) {
                        if (!$filterList.hasClass('d-none')) {
                            $filterList.addClass('d-none')
                        }
                    } else if ($filterList.hasClass('d-none')) {
                        $filterList.removeClass('d-none')
                    }

                    if (e.type === 'click') {
                        $form.trigger('filter.applied');
                    }
                };

                $element.find('[data-filter]').on('click filter.click', function (e) {
                    fn(e);
                });

                $element.find('[data-type="clear-all"]').on('click', function () {
                    let $this = $(this);
                    let $form = $this.closest('[data-filter="work"]');

                    let $filterList = $form.find('[data-type="selected"]');
                    let $filterListContainer = $filterList.find('[data-type="selected-container"]');

                    $filterListContainer.find('[data-filter].active').remove();
                    $form.find('[data-filter].active').removeClass('active');

                    if (!$filterListContainer.html().trim()) {
                        if (!$filterList.hasClass('d-none')) {
                            $filterList.addClass('d-none')
                        }
                    } else if ($filterList.hasClass('d-none')) {
                        $filterList.removeClass('d-none')
                    }

                    $form.trigger('filter.clean.all');
                });

                let loadFilters = function (location) {
                    if (location === undefined) {
                        location = window.location;
                    }

                    const params = new URLSearchParams(location.search)

                    for (let param of params) {
                        let name = param[0].slice(0, -2);
                        let value = param[1];

                        $element.find('[data-filter="' + name + '"][data-value="' + value + '"]:not(.active)').trigger('filter.click');
                    }
                }

                loadFilters();

                $(window).on('popstate', function () {
                    reloadGrid(document.location);
                    loadFilters(document.location);
                });

            });
        }
    });
}());
