$(function () {
    let $collapse = $('[data-map="container"]');

    if ($collapse.length > 0) {
        let loadMap = function ($this) {
            $this = $this.find('[data-map="false"]');

            if ($this.length === 0) {
                return;
            }

            let lat = $this.data('lat');
            let lng = $this.data('lng');
            let icon = $this.data('icon');

            try {
                let $map = GMaps({
                    div: '#' + $this.attr('id'),
                    lat: lat,
                    lng: lng,
                    disableDefaultUI: true,
                    zoom: 16,
                });

                $map.setCenter(lat, lng - 0.001);
                $map.addMarker({
                    lat: lat,
                    lng: lng,
                    icon: icon,
                });

                $this.attr('data-map', 'true')
            } catch (e) {

            }
        };

        let hash = window.location.hash;

        if (hash) {
            let open_hash = function (hash) {
                let $target = $('[data-target="' + hash + '"]');

                if ($target && $target.attr('aria-expanded') !== 'true') {
                    $target.trigger('click');
                }

                $('body,html').animate({scrollTop: 0}, 'swing');
            }

            open_hash(hash);

            $(window).on('hashchange', function () {
                open_hash(window.location.hash);
            });
        }

        loadMap($collapse.filter('.show'));

        $collapse.on('click', function (e) {
            let idx = $(this).index('[data-toggle="collapse"]');

            if ($('.dt-office.show').index('.collapse') === idx) {
                e.stopPropagation();
            }
        });

        $collapse.on('shown.bs.collapse', function () {
            loadMap($(this));
        });
    }
});
