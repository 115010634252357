/**
 * We'll load jQuery and the Bootstrap jQuery plugin which provides support
 * for JavaScript based Bootstrap features such as modals and tabs. This
 * code may be modified to fit the specific needs of your application.
 */
window.$ = window.jQuery = require('jquery');
window.Popper = require('popper.js');
window.owlCarousel = require('owl.carousel');
window.Masonry = require('masonry-layout');
window.jQueryBridget = require('jquery-bridget');

window.imagesLoaded = require('imagesloaded');
window.InfiniteScroll = require('infinite-scroll');
window.InfiniteScroll.imagesLoaded = window.imagesLoaded;
jQueryBridget('infiniteScroll', InfiniteScroll, $);
jQueryBridget('masonry', Masonry, $);

window.GMaps = require('gmaps');

window.WOW = require('wow.js');

jQuery.event.special.touchstart = {
  setup: function (_, ns, handle) {
    this.addEventListener('touchstart', handle, { passive: !ns.includes('noPreventDefault') });
  },
};
jQuery.event.special.touchmove = {
  setup: function (_, ns, handle) {
    this.addEventListener('touchmove', handle, { passive: !ns.includes('noPreventDefault') });
  },
};
jQuery.event.special.wheel = {
  setup: function (_, ns, handle) {
    this.addEventListener('wheel', handle, { passive: true });
  },
};
jQuery.event.special.mousewheel = {
  setup: function (_, ns, handle) {
    this.addEventListener('mousewheel', handle, { passive: true });
  },
};

import { Collapse, Modal } from 'bootstrap';

window.Collapse = Collapse;
window.Modal = Modal;

$(function () {
  let current_page_url = window.location.href;

  $('[name="param[Page URL]"]').val(current_page_url);

  $('[data-vc-accordion]').on('click', function (e) {
    e.preventDefault();
    e.stopPropagation();

    let $this = $(this);
    let id = $this.attr('href');
    $(id).toggleClass('vc_active');
  });

  new window.WOW({
    boxClass: 'wow',
    animateClass: 'animated',
    mobile: true,
    live: true,
    offset: -200,
    delay: 600,
  }).init();


  const $landingForm = $('#dt-landing-form-wrapper');
  const $landingFormButton = $('.btn-toggle-form');
  let windowWidth = window.screen.width;

  $landingFormButton.on('click', function (e) {
    if (windowWidth >= 768) {
      e.stopPropagation();
    }
  });

  const collapseLandingForm = function () {
    if (window.screen.width <= 768) {
      $landingForm.removeClass('show');
      $landingFormButton.addClass('collapsed');
    } else {
      $landingForm.addClass('show');
      $landingFormButton.removeClass('collapsed');
    }
  };

  collapseLandingForm();

  $(window).on('resize', function () {
    windowWidth = window.screen.width;

    collapseLandingForm();
  });
});

require('./vc/elements/dt_slider');
require('./plugins/infinite-load');
require('./plugins/filters');
require('./plugins/map');
require('./plugins/masonry');
require('./plugins/video-bg');
require('./header');
require('./sidebar');
require('./vc/elements/dt_modal.js');
