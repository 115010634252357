$(function () {
    if ($(window).width() >= 1200) {
        let $element = $('[data-video]');

        if ($element.length > 0) {
            const anime = require('animejs/lib/anime.min');

            $element.append($('<div />', {'class': 'dt-video-bg'}));

            let $video = $('video', $element);

            $('.dt-home-subtitle', $element).append($('<div />', {class: 'dt-home-separate'}))

            let animationOut = async function ($video, $element) {
                let tl = anime.timeline({
                    easing: 'linear',
                    complete: () => {
                        $video.get(0).play();
                    }
                });

                tl.add({
                    targets: '[data-video] .dt-home-title .word',
                    translateY: [0, '125%'],
                    duration: 150,
                    delay: (el, i) => 75 * i,
                })
                    .add({
                        targets: '[data-video] .dt-home-subtitle .word',
                        translateY: [0, '125%'],
                        duration: 75,
                        delay: (el, i) => 25 * i,
                    }, 350)
                    .add({
                        targets: '.dt-home-separate',
                        height: {
                            value: [2, 100],
                            duration: 100,
                        },
                        translateY: {
                            value: [0, -100],
                            duration: 100,
                        }
                    })
                    .add({
                        targets: '.dt-home-separate',
                        height: {
                            value: [100, 0],
                            duration: 100,
                        },
                        easing: "linear",
                    })
                    .add({
                        targets: '[data-video] .dt-video-bg',
                        width: ['100vw', 0],
                        right: [0, '-200'],
                        duration: 350,
                    }, 500);
            };

            let animationIn = ($video) => {
                let tl = anime.timeline({
                    easing: 'linear'
                });

                tl.add({
                    targets: '[data-video] .dt-video-bg',
                    width: [0, '100vw'],
                    right: ['-500', 0],
                    duration: 500,
                }, 150)
                    .add({
                        targets: '.dt-home-separate',
                        height: {
                            value: [0, 100],
                            duration: 100,
                        },
                        easing: "linear",
                    }, 550)
                    .add({
                        targets: '.dt-home-separate',
                        height: {
                            value: [100, 2],
                            duration: 100,
                        },
                        translateY: {
                            value: [-100, 0],
                            duration: 100,
                        }
                    }, 650)
                    .add({
                        targets: '[data-video] .dt-home-title .word',
                        translateY: ['125%', 0],
                        duration: 150,
                        delay: (el, i) => 75 * i,
                    })
                    .add({
                        targets: '[data-video] .dt-home-subtitle .word',
                        translateY: ['125%', 0],
                        duration: 50,
                        delay: (el, i) => 25 * i,
                        complete: () => {
                            $video.remove();
                        }
                    }, '+=150');
            }

            setTimeout(() => {
                $video.get(0).play();

                $video.on('loadeddata', function () {
                    $video.get(0).pause();
                });

                $video.on('canplaythrough', function () {
                    animationOut($video, $element);
                });

                $video.on('ended', function () {
                    animationIn($video);
                });
            }, 2500);
        }
    }
});
