$(document).ready(function () {
    let $element = $('[data-plugin="back-to-top"]');

    if ($element.length > 0) {
        $element.on('click', function (e) {
            e.preventDefault();

            $('html, body').animate({
                scrollTop: 0
            }, 700);
        });
    }
});
