$(function () {
    let $infinite = $('[data-plugin="infinite-load"]');

    if ($infinite.length > 0) {
        $infinite.each(function () {
            let $element = $(this);

            let $grid = $element.masonry({
                itemSelector: '.masonry-column'
            });

            let msnry = $grid.data('masonry');

            if ($element.data('load') === 'load') {
                $element.infiniteScroll({
                    path: '.pagination__next',
                    nextSelector: '.pagination__next',
                    append: '[data-content="infinite-load"]',
                    history: false,
                    checkLastPage: true,
                    hideNav: '.pagination__next',
                    debug: false,
                    outlayer: msnry,
                });
            }
        });
    }
});
