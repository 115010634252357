$(function () {
    let $element = $('[data-plugin="sticky"]');

    if ($element.length > 0) {
        let $html = $('html');
        let position = $element.data('scroll');

        if (window.pageYOffset >= position) {
            $html.addClass('sticky');
        }

        $(window).scroll(function () {
            if ($(document).scrollTop() > position) {
                $html.addClass('sticky');
            } else {
                $html.removeClass('sticky');
            }
        });

        $(window).scroll(function () {
            // get the scroll position top
            let $container = $('#left-sidebar');
            let $footer = $('footer#footer');

            position = $container.offset().top + $container.height() + 100;

            let footer_start = $footer.offset().top;

            if ($container.offset().top + $container.height() >= footer_start - 10) {
                $container.css('position', 'absolute').css('top', (footer_start - $container.outerHeight()) + 'px')
            } else if ($(document).scrollTop() + window.innerHeight < footer_start) {
                $container.css('position', 'fixed').css('top', 'unset');
            }
        });
    }

    let $button_close = $('[data-toggle="collapse"].hamburger');

    if ($button_close.length > 0) {
        $button_close.on('click', function () {
            let $this = $(this);
            let $body = $('body');

            if (!$this.hasClass('is-active') && $this.attr('aria-expanded') === 'false') {
                $this.addClass('is-active');

                if ($(window).width() < 992) {
                    $body.addClass('menu-open');
                }
            } else {
                $this.removeClass('is-active');
                $body.removeClass('menu-open');
            }
        });
    }

    function toggleDropdown(e) {
        let $dropdown = $(this);
        const $menu = $('> .dropdown-menu', $dropdown);

        if(e.type === 'mouseleave') {
            $menu.find('[aria-expanded]').attr('aria-expanded', 'false');
            $menu.find('.show').removeClass('show');
        }

        if ($(window).width() < 992) {
            $menu.removeClass('fadeOut animated');

            return;
        }

        try {
            const shouldOpen = e.type !== 'click' && $dropdown.is(':hover');

            $menu.toggleClass('show', shouldOpen);
            $dropdown.toggleClass('show', shouldOpen);

            let $btn = $('> [data-toggle="dropdown"]', $dropdown);
            $btn.attr('aria-expanded', shouldOpen);

            if ($btn.attr('aria-expanded') === 'true') {
                if ($(window).width() > 991 && $(window).width() - ($menu.offset().left + $menu.outerWidth()) < 0) {
                    $menu.css('left', 'auto')
                    $menu.css('right', 0)
                }

                $menu.removeClass('fadeOut animated').addClass('fadeIn animated');
                $dropdown.find('> .nav-item').addClass('fadeInDown animated')
            } else {
                $menu.removeClass('fadeIn animated').addClass('fadeOut animated');
                $menu.find('> .nav-item').removeClass('fadeInDown animated')
            }
        } catch (e) {

        }
    }

    let $body = $('body');

    $body.on('mouseenter mouseleave', '.nav.navbar-nav > li.nav-item.menu-item-has-children', toggleDropdown);
    $body.on('click', '.nav.navbar-nav > li.nav-item.menu-item-has-children', toggleDropdown);

    $body.find('#top-menu').find('.dropdown-item,.nav-link').removeAttr('title');

    $('.dropdown-menu .dropdown-toggle').on('click', function (e) {
        e.stopPropagation();

        let $this = $(this);

        if (!$this.next().hasClass('show')) {
            $this.parents('.dropdown-menu').first().find('.show').removeClass('show');
        }

        let $subMenu = $this.next('.dropdown-menu');

        $subMenu.toggleClass('show fadeIn animated');

        $this.parents('li.nav-item.dropdown.show').on('hidden.bs.dropdown', function (e) {
            $('.dropdown-submenu .show').removeClass('show');
        });

        $this.attr('aria-expanded', $this.attr('aria-expanded') === 'false');
    });
});
