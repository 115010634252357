$(function () {
    let $sliders = $('[data-plugin="dt-slider"]');

    $sliders.each(function (i) {
        let $slider = $($sliders[i]);

        let params = {
            loop: true,
            margin: 0,
            mouseDrag: false,
            dots: $slider.data('dots') === 1,
            nav: $slider.data('nav') === 1,
            navText: [
                '<i class="dt-icon icon-chevron-left"></i>', '<i class="dt-icon icon-chevron-right"></i>'
            ],
            responsive: {
                0: {
                    autoHeight: true
                },
                992: {
                    autoHeight: false
                }
            },
            items: 1
        };

        if (navigator.userAgent.indexOf("Edge") === -1) {
            params.animateIn = 'fadeIn';
            params.animateOut = 'fadeOut';
        }

        let dots_container = $slider.data('dots-container');

        if (dots_container) {
            params.dotsContainer = dots_container;
        }

        let owl = $slider.owlCarousel(params);

        if ($slider.closest('.dt-slider-list').length > 0 || $slider.closest('.dt-slider-list-page').length > 0) {
            let $dots = $slider.closest('.dt-slider').find('.owl-dots');

            $(window).on('resize', function () {
                if ($(window).width() >= 1200) {
                    $dots.removeAttr('style');
                } else {
                    $dots.find('.clone').remove();
                }
            });

            let cloneSlides = (e) => {
                if ($(window).width() < 1200) {
                    let active = e !== undefined ? e.page.index : undefined;

                    if (active === undefined) {
                        active = $dots.find('.owl-dot.active').attr('data');
                    }

                    let width = 0;

                    $dots.find('.owl-dot').each(function () {
                        let $this = $(this);

                        if ($this.attr('data') < active) {
                            width += $this.outerWidth() + parseInt($this.css('marginRight'));
                            let $clone = $this.clone().addClass('clone');

                            $dots.append($clone);
                        }
                    });

                    $dots.css('transform', 'translateX(' + (-width) + 'px)');

                    $dots.data('active', active);
                }
            }

            $slider.on('changed.owl.carousel resized.owl.carousel', function (e) {
                cloneSlides(e);
            });
        }

        if (dots_container) {
            $(dots_container + ' .owl-dot').on('click', function () {
                owl.trigger('to.owl.carousel', [$(this).index(), 300]);
            });
        }
    });
});
