$(function () {
  const $modals = $('.plain-modal');

  $modals.each(function () {
    const $modal = $(this);
    const $modalType = $modal.data('modal');

    if ($modalType === 'class') {
      const $buttonClassToFind = $modal.data('modal-class');
      const $button = $('.' + $buttonClassToFind);

      $button.on('click', function (e) {
        e.preventDefault();

        $modal.modal('show');
      });
    }

    if ($modalType === 'time') {
      const $modalTime = $modal.data('trigger-time') ?? '30';

      setTimeout(function () {
        $modal.modal('show');

      }, ($modalTime * 1000));
    }

    if ($modalType === 'button') {
      const $button = $('.launch-modal');

      /**
       * Launch Acceptance modal on button click
       */
      if ($modal.length > 0) {
        let $modal_btn = $('.modal-redirect');

        $button.on('click', function (e) {
          e.preventDefault();

          $modal.modal('show');
        });

        $modal_btn.on('click', function (e) {
          $modal.modal('hide');
        });

        acceptEula($modal);
      }

      /**
       * Gets input and submit button and uncheck on pressed
       * @param $modal
       */
      function acceptEula($modal) {
        let $this = $($modal);

        let $modal_btn = $this.find('a.modal-redirect');
        let $modal_check = $this.find('input.acceptance-check');

        //Init to uncheck
        $modal_btn.addClass('disabled');
        $modal_check.prop('checked', false);

        $modal_check.on('click', function () {
          if ($(this).is(':checked')) {
            $modal_btn.removeClass('disabled');
          } else {
            $modal_btn.addClass('disabled');
          }
        });

        //Uncheck on hide modal
        $this[0].addEventListener('hidden.bs.modal', function () {
          $modal_check.prop('checked', false);
          $modal_btn.addClass('disabled');
        });
      }
    }
  });

  /**
   * Launch Single post modal on half post scroll
   */

  const $singleModal = $('.single-post-modal');
  const $singleContent = $('.post-container .post-content');
  const $singleModalTrigger = $singleModal.data('trigger');

  // Can only be opened once
  let hasBeenOpen = false;

  if ($singleContent.length > 0) {
    // If cookie is not set, continues
    const hasCookie = readCookie('_has_entered');

    if (!hasCookie) {
      const $singleContentHeight = $singleContent.innerHeight();

      if ($singleModalTrigger === 'time') {
        const $modalTime = $singleModal.data('trigger-time') ?? '30';

        setTimeout(function () {
          $singleModal.modal('show');

          hasBeenOpen = true;

          createCookie('_has_entered', true);
        }, ($modalTime * 1000));

      } else {
        $(window).scroll(function (e) {
          const scroll = $(window).scrollTop();

          if ($singleContentHeight * 0.75 < scroll && !hasBeenOpen) {
            if ($singleModal.length > 0) {
              hasBeenOpen = true;

              setTimeout(function () {
                $singleModal.modal('show');

                createCookie('_has_entered', true);
              }, 1000);
            }
          }
        });
      }
    }
  }
});

function createCookie(name, value) {
  let expires;

  const date = new Date();

  date.setMonth((date.getMonth() + 1));
  expires = '; expires=' + date.toUTCString();

  document.cookie = encodeURIComponent(name) + '=' + encodeURIComponent(value) + expires + '; path=/';
}

function readCookie(name) {
  const nameEQ = encodeURIComponent(name) + '=';
  const ca = document.cookie.split(';');

  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];

    while (c.charAt(0) === ' ') c = c.substring(1, c.length);
    if (c.indexOf(nameEQ) === 0) return decodeURIComponent(c.substring(nameEQ.length, c.length));
  }
  return null;
}
